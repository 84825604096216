<template>
	<div style="margin: 0 16px;margin-top: 16px;">
		<div v-if="foldId==0">
			 <a-result status="404" title="暂未参与任何项目组" sub-title="请您加入项目组后再使用">
			    <template #extra>
			      <!-- <a-button type="primary" @click="createdFold">
			        创建文件夹
			      </a-button> -->
			    </template>
			  </a-result>
		</div>
		<div v-if="foldId!=0">
			<screen :state="'group'" :capacity="capacity" @submit="submit"></screen>
			<copy :projectId="foldId" :id="id" :id2="id2" v-if="copyLoad" :hide="hide"></copy>
			<rename :name="name" :projectId="foldId" :state="'group'" :id="id" v-if="renameLoad" :hide="hide"></rename>
			<sTable v-if="tableFlash" :rowSelection="rowSelection" :columns="columns" :data="data" :pagination="pagination" @change="change">
				<template slot="action" slot-scope="action">
					<div style="display: flex;">
						<div @click="copy(action)"
							style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">复制</div>
						
						<!-- <div @click="downloadcloudfile(action)" style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">下载</div> -->
						<div @click="downloadFileFromUrl(action)" style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">下载</div>
						<div v-if="action.record.hasEditFileName==1" @click="rename(action)" style="font-size: 14px;color: #1890FF; cursor: pointer;margin-right: 24px;">重命名</div>
						<a-popconfirm
						v-if="action.record.hasDeleteFile==1"
						    title="确认是否删除该文件"
						    ok-text="是"
						    cancel-text="否"
						    @confirm="deleteitemfile(action)"
						  >
						<div style="font-size: 14px;color: #FF4D4F; cursor: pointer;margin-right: 24px;">删除</div>
						</a-popconfirm>
						
			
					</div>
				</template>
			</sTable>
		</div>
		
	</div>
</template>
<script>
	import sTable from '../../../components/sTable.vue'
	import {
		returnprojectfiles,
		deleteitemfile,
		downloadcloudfile
	} from '../../../api/anotherIndex'
	import screen from '../../../components/disk/screen.vue'
	import rename from '../../../components/disk/cloudFile/rename.vue'
	import copy from '../../../components/disk/item/copy.vue'
	import {
		getProjectCloudiskFileUrl
	} from '@/api/others.js'
	export default {
		components: {
			sTable,
			screen,
			rename,
			copy
		},
		props: ['foldId'],
		data() {
			const rowSelection = {
				onChange: (selectedRowKeys, selectedRows) => {
			
					let ids = selectedRows.map((n) => {
						return n.projectFileId
					})
					this.ids = ids.join(',')
					let ids2 = selectedRows.map((n) => {
						return n.projectId
					})
					this.ids2 = ids2.join(',')
				}
			}
			return {
				renameLoad:false,	// 是否加载重命名
				name:undefined,	
				tableFlash:true,
				copyLoad:false,
				hide:false,
				capacity: 0,
				columns: [{
						title: '文件名',
						dataIndex: 'fileName',
						key: 'fileName',
						ellipsis:true,
				
					},
					{
						title: '分享时间',
						dataIndex: 'shareTime',
						key: 'shareTime',
					},
					{
						title: '分享者',
						dataIndex: 'shareName',
						key: 'shareName',
					},
					{
						title: '大小',
						dataIndex: 'fileSize',
						key: 'fileSize',
					},
				
					{
						title: '操作',
						key: 'action',
						dataIndex: 'fileName',
						scopedSlots: {
							customRender: 'action'
						},
						width: '320px'
					},
				],
				data: [],
				rowSelection,
				pagination: {
					total: 0,
					pageSize:10,
					current:1,
					showTotal: total => `共 ${total} 项`,
					showSizeChanger: true,
					pageSizeOptions: ['5', '10', '15', '20'],
					//showTotal: () => total => `共 ${total} 项`
				},
				uploadStartTime: '',
				uploadEndTime: '',
				shareUserName: '',
				fileType: undefined,
			}
		},
		created() {
			/* let data = '?ProjectId='+this.foldId+'&PageNum='+this.pagination.current+
			'&PageSize='+this.pagination.pageSize */
			if(!this.foldId) {
				return ;
			}
			this.returnprojectfiles()
			
		},
		/* mounted() {
			this.returnprojectfiles()
		}, */
		methods:{
			change(data){
				//console.log(data)
				this.pagination.pageSize = data.pageSize
				this.pagination.current = data.current
				this.fileName = data.fileName;
				this.uploadStartTime = data.uploadStartTime;
				this.uploadEndTime = data.uploadEndTime;
				this.shareUserName = data.shareUserName;
				this.fileType = data.fileType;
				/* let datas = '?PageNum=' + this.pagination.current + '&PageSize=' + this.pagination.pageSize +
					'&ProjectId=' + this.foldId
				if (data.fileType) {
					datas += '&FileType=' + data.fileType
				}
				if (data.fileName) {
					datas += '&FileName=' + encodeURIComponent(data.fileName)
				}
				if (data.UploadStartTime) {
					datas += '&UploadStartTime=' + data.uploadStartTime
				}
				if (data.UploadEndTime) {
					datas += '&UploadEndTime=' + data.UploadEndTime
				}
				if (data.shareUserName) {
					datas += '&ShareUserName=' + encodeURIComponent(data.shareUserName)
				} */
				
				this.returnprojectfiles()
			},
			async returnprojectfiles(){
				this.$store.commit('updateSpin',true)
				const res = await returnprojectfiles({
					PageNum: this.pagination.current,
					PageSize: this.pagination.pageSize,
					ProjectId: this.foldId,
					ShareStartTime: this.uploadStartTime,
					ShareEndTime: this.uploadEndTime,
					ShareUserName: this.shareUserName
				})
				if(res.success){
					this.data = res.data.data
					this.pagination.total = res.data.count
				}
				this.$store.commit('updateSpin',false)
			},
			async submit(data){
				/* let datas = '?PageNum='+this.pagination.current+'&PageSize='+this.pagination.pageSize+'&ProjectId=' + this.foldId +
					'&FileName=' + encodeURIComponent(data.fileName) +
					'&ShareStartTime=' + data.uploadStartTime +
					'&ShareEndTime=' + data.uploadEndTime+
					'&ShareUserName=' + encodeURIComponent(data.shareUserName) */
				this.fileName = data.fileName;
				this.uploadStartTime = data.uploadStartTime;
				this.uploadEndTime = data.uploadEndTime;
				this.shareUserName = data.shareUserName;
				this.fileType = data.fileType;
				this.pagination.current = 1;
				/*  */
				this.returnprojectfiles()
				//console.log(datas)
			},
			rename(data){
				this.renameLoad = false
				setTimeout(()=>{
					this.id = data.record.projectFileId
					this.name = data.slotProps.substr(0, data.slotProps.lastIndexOf('.') )
					this.renameLoad = true
					this.hide = true
				},100)
			},
			copy(data){
				let id = undefined
				let id2 = undefined
				if (data) {
					id = data.record.projectFileId
					id2 = data.record.projectId
				} else {
					id = this.ids
					id2 = this.ids2
				}
				if (!id) {
					this.$message.error('请勾选所需要复制的文件')
					return
				}
				this.copyLoad = false
				setTimeout(() => {
					this.copyLoad = true
					this.hide = true
					this.id = id
					this.id2 = id2
				}, 100)
			},
			async deleteitemfile(data){
				let id = data.record.projectFileId
				//let datad = '?FileId='+id+'&ProjectId='+this.foldId
				const res = await deleteitemfile({
					FileId: id,
					ProjectId: this.foldId
				})
				if(res.success){
					this.$message.success('删除成功')
					let datas = '?ProjectId='+this.foldId+'&PageNum='+this.pagination.current+
					'&PageSize='+this.pagination.pageSize
					this.returnprojectfiles(datas)
				}
				
				
			},
			/* downloadcloudfile(data) {
				let id = data.record.projectFileId
				let datas = '?FileId=' + id+'&ProjectId='+data.record.projectId
				const that = this;
				downloadcloudfile(datas).then(async (res) => {
					if (res.data.type === 'application/json') {
						const error = await that.blobToObj(res.data);
						this.$message.error(error.message)
						return Promise.resolve(false);
					}
					const blob = new Blob([res.data], {
						type: 'application/octet-stream'
					}); //处理文档流
					const fileName = data.slotProps;
					const elink = document.createElement('a');
					elink.download = fileName;
					elink.style.display = 'none';
					elink.href = URL.createObjectURL(blob);
					document.body.appendChild(elink);
					elink.click();
					URL.revokeObjectURL(elink.href); // 释放URL 对象
					document.body.removeChild(elink);
				})
			
			}, */
			async blobToObj(data) {
				return new Promise((resolve, reject) => {
					let reader = new FileReader();
					reader.readAsText(data, 'utf-8');
					reader.onload = function () {
						try {
							resolve(JSON.parse(reader.result))
						} catch (error) {
							resolve({
								code: 200,
								message: '获取文件信息成功'
							})
						}
					}
				})
			},
			async downloadFileFromUrl(file) {
				this.$store.commit('updateSpin',true)
				const fileName = file.slotProps;
				const res = await getProjectCloudiskFileUrl({
					FileId: file.record.projectFileId,
					isPreview: false,
					isPublicRead: false,
					saveAsName: fileName,
				});
				if(res.success) {
					const elink = document.createElement('a');
					elink.download = fileName;
					elink.style.display = 'none';
					elink.href = res.data
					//elink.target = "_blank";
					document.body.appendChild(elink);
					elink.click();
					document.body.removeChild(elink);
				}
				this.$store.commit('updateSpin',false)
			}
		},
		}
</script>

<style>
</style>